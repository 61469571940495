/** Ionic Variables and Theming. For more info, please see: **/

/** http://ionicframework.com/docs/theming/ **/

@import 'https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,700&display=swap';

/** Ionic CSS Variables **/
:root {
  /** Foreground Primary **/
  --ion-foreground-primary: #0f1111;
  --ion-foreground-primary-rgb: 15, 17, 17;
  --ion-foreground-primary-contrast: #fff;
  --ion-foreground-primary-contrast-rgb: 255, 255, 255;

  /** Foreground Secondary **/
  --ion-foreground-secondary: #565959;
  --ion-foreground-secondary-rgb: 86, 89, 89;
  --ion-foreground-secondary-contrast: #ddd;
  --ion-foreground-secondary-contrast-rgb: 221, 221, 221;

  /** Font **/
  --ion-font-family: 'Roboto Flex', sans-serif;
  --ion-font-color: var(--ion-foreground-primary);

  /** Primary **/
  --ion-color-primary: #ffa600;
  --ion-color-primary-rgb: 255, 166, 0;
  --ion-color-primary-contrast: var(--ion-foreground-primary);
  --ion-color-primary-contrast-rgb: var(--ion-foreground-primary-rgb);
  --ion-color-primary-shade: #b37400;
  --ion-color-primary-tint: #ffc14d;

  /** Secondary **/
  --ion-color-secondary: #ffd000;
  --ion-color-secondary-rgb: 255, 208, 0;
  --ion-color-secondary-contrast: var(--ion-foreground-primary);
  --ion-color-secondary-contrast-rgb: var(--ion-foreground-primary-rgb);
  --ion-color-secondary-shade: #b39200;
  --ion-color-secondary-tint: #ffde4d;

  /** Tertiary **/
  --ion-color-tertiary: #ff7b00;
  --ion-color-tertiary-rgb: 255, 123, 0;
  --ion-color-tertiary-contrast: var(--ion-foreground-primary);
  --ion-color-tertiary-contrast-rgb: var(--ion-foreground-primary-rgb);
  --ion-color-tertiary-shade: #b35600;
  --ion-color-tertiary-tint: #ffa34d;

  /** Alter Primary **/
  --ion-color-alter-primary: #121070;
  --ion-color-alter-primary-rgb: rgb(18 16 112);
  --ion-color-alter-primary-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-alter-primary-contrast-rgb: var(
    --ion-foreground-primary-contrast-rgb
  );
  --ion-color-alter-primary-shade: #0b0a43;
  --ion-color-alter-primary-tint: #19169c;

  /** Alter Secondary **/
  --ion-color-alter-secondary: #133786;
  --ion-color-alter-secondary-rgb: rgb(19 55 134);
  --ion-color-alter-secondary-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-alter-secondary-contrast-rgb: var(
    --ion-foreground-primary-contrast-rgb
  );
  --ion-color-alter-secondary-shade: #0d2559;
  --ion-color-alter-secondary-tint: #194ab3;

  /** Alter Tertiary **/
  --ion-color-alter-tertiary: #0a0a47;
  --ion-color-alter-tertiary-rgb: rgb(10 10 71);
  --ion-color-alter-tertiary-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-alter-tertiary-contrast-rgb: var(
    --ion-foreground-primary-contrast-rgb
  );
  --ion-color-alter-tertiary-shade: #030316;
  --ion-color-alter-tertiary-tint: #0f0f70;

  /** Info (same with alter-secondary) **/
  --ion-color-info: #133786;
  --ion-color-info-rgb: rgb(19 55 134);
  --ion-color-info-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-info-contrast-rgb: var(--ion-foreground-primary-contrast-rgb);
  --ion-color-info-shade: #0d2559;
  --ion-color-info-tint: #194ab3;

  /** Success **/
  --ion-color-success: #00661b;
  --ion-color-success-rgb: 0, 102, 27;
  --ion-color-success-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-success-contrast-rgb: var(--ion-foreground-primary-contrast-rgb);
  --ion-color-success-shade: #003d10;
  --ion-color-success-tint: #009929;

  /** Warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: var(--ion-foreground-primary);
  --ion-color-warning-contrast-rgb: var(--ion-foreground-primary-rgb);
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** Danger **/
  --ion-color-danger: #b30018;
  --ion-color-danger-rgb: 179, 0, 24;
  --ion-color-danger-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-danger-contrast-rgb: var(--ion-foreground-primary-contrast-rgb);
  --ion-color-danger-shade: #800011;
  --ion-color-danger-tint: #e6001f;

  /** Dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-dark-contrast-rgb: var(--ion-foreground-primary-contrast-rgb);
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** Medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: var(--ion-foreground-primary-contrast);
  --ion-color-medium-contrast-rgb: var(--ion-foreground-primary-contrast-rgb);
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** Light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: var(--ion-foreground-primary);
  --ion-color-light-contrast-rgb: var(--ion-foreground-primary-rgb);
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.ion-color-alter-primary {
  --ion-color-base: var(--ion-color-alter-primary);
  --ion-color-base-rgb: var(--ion-color-alter-primary-rgb);
  --ion-color-contrast: var(--ion-color-alter-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alter-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-alter-primary-shade);
  --ion-color-tint: var(--ion-color-alter-primary-tint);
}

.ion-color-alter-secondary {
  --ion-color-base: var(--ion-color-alter-secondary);
  --ion-color-base-rgb: var(--ion-color-alter-secondary-rgb);
  --ion-color-contrast: var(--ion-color-alter-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alter-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-alter-secondary-shade);
  --ion-color-tint: var(--ion-color-alter-secondary-tint);
}

.ion-color-alter-tertiary {
  --ion-color-base: var(--ion-color-alter-tertiary);
  --ion-color-base-rgb: var(--ion-color-alter-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-alter-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alter-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-alter-tertiary-shade);
  --ion-color-tint: var(--ion-color-alter-tertiary-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}
