$color-primary: var(--ion-foreground-primary);
$color-secondary: var(--ion-foreground-secondary);
$color-danger: var(--ion-color-danger);
$border-radius-small: 8px;

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--ion-color-primary);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 8px;
}

h1,
h2,
h3,
ion-title {
  font-weight: 600;
}

h4,
h5,
h6 {
  font-weight: 500;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ion-icon {
  pointer-events: none;
}

ion-button {
  margin: 0;
  text-transform: none;
}

ion-card {
  --color: inherit;

  margin: 0;
  font-size: inherit;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
ion-button {
  // to override ion-label and ion-button shadow root css white-space:nowrap
  white-space: normal;
}

button.alert-button.button-primary {
  border: 1px solid $color-primary;
  border-radius: $border-radius-small;
  color: $color-primary;
}

button.alert-button.button-secondary {
  border: 1px solid $color-secondary;
  border-radius: $border-radius-small;
  color: $color-secondary;
}

button.alert-button.button-danger {
  border: 1px solid $color-danger;
  border-radius: $border-radius-small;
  color: $color-danger;
}

/**
 * Ionic requires putting modal/alert styles in global css.
 */
.modal-full-height {
  &::part(content) {
    height: 100%;
    background-color: var(--ion-color-light-shade);

    // Media query at ionic breakpoint md
    /* stylelint-disable-next-line media-feature-range-notation */
    @media only screen and (min-width: 768px) {
      height: auto;
      min-height: 500px;
      max-height: 95vh;
      overflow-y: auto;
    }
  }
}

.modal-size-full {
  &::part(content) {
    width: 100%;
  }
}

.modal-size-fit-content {
  &::part(content) {
    width: fit-content;
  }
}

ion-modal > .ion-page {
  // Media query at ionic breakpoint md
  /* stylelint-disable-next-line media-feature-range-notation */
  @media only screen and (min-width: 768px) {
    // to override ion-modal > .ion-page height
    height: auto;
  }
}

.alert-wide {
  --max-width: min(90vw, 600px);

  .alert-wrapper {
    padding-left: 30px;
    background-image: linear-gradient(
      to right,
      var(--color) 40px,
      transparent 40px
    );
  }
}

.alert-info {
  --color: var(--ion-color-info);
}

.alert-success {
  --color: var(--ion-color-success);
}

.alert-warning {
  --color: var(--ion-color-warning);
}

.alert-danger {
  --color: var(--ion-color-danger);
}

// Hide the google ReCaptcha badge
.grecaptcha-badge {
  visibility: hidden;
}

ion-item {
  --background-hover-opacity: 0.15;
  --background-focused-opacity: 0.3;
}

// Allow printing pages in Ionic. Without this code printing is wonky.
// https://github.com/ionic-team/ionic-framework/issues/19886#issuecomment-1531073496
@media print {
  // @page {
  //   margin: 0;
  // } // removing url and page # of <totalpages> header/footers

  ion-menu,
  ion-header,
  ion-toolbar {
    display: none;
  }

  .ion-page {
    position: initial !important;
    display: initial !important;
  }

  body {
    position: initial !important;
    overflow: auto !important;
    max-height: initial !important;
  }

  ion-router-outlet {
    position: initial !important;
    overflow: auto !important;
    contain: none !important;
  }

  ion-content {
    --offset-bottom: unset !important;

    position: unset !important;
    display: unset !important;
  }

  ion-content::part(background),
  .ion-page-hidden {
    display: none !important;
  }
}
